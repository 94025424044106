import './DisplayImage.css';

const DisplayImage = ({ imgSrc, text, url, disabled }) => {
  return (
    <a href={url} className="display-container" style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      <div className="display-font" style={{ filter: disabled ? 'blur(3px) grayscale(0.4) opacity(0.6)' : 'none' }}>
        <img className="display-image" src={imgSrc} alt={text} />
        <div className="display-text">{text}</div>

      </div>
      {/* {disabled && <div className="display-soon">COMING SOON</div>} */}
    </a>
  );
}

export default DisplayImage;
