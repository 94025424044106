import './TutorialPage.css';
import headshot from '../assets/headshot.png'

const TutorialPage = () => {

  return (
    <div className="tutorial-root">
      <h2>Mural Wall Tutoral</h2>
      <div className="tutorial-block">
        <div className='tutoral-video'>
          <iframe className='video' src="https://player.vimeo.com/video/566353702" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Gold Leaf Wall Tutorial - Copeley Designs Art" />
        </div>
        <div className='tutorial-text'>
          <p>
            These DIY wall styles bring your interiors shine and texture, they are cost effective, and are also easy to remove in the future (just sand away).
          </p>
          <p>
            Intersted in purchasing the Mural Wall Tutoral? Want the artist to paint a mural wall for your space? Contact copeleydesigns@gmail.com
          </p>
        </div>
      </div>
    </div >
  );
}

export default TutorialPage;
