import art from '../assets/art.png';
import bracelets from '../assets/bracelets.png';
import classes from '../assets/classes.png';
import mural from '../assets/mural.png';
import prints from '../assets/prints.png';
import DisplayImage from '../components/DisplayImage';
import './HomePage.css';

const HomePage = () => {

  return (
    <div className="home-root">
      <DisplayImage text="Original Art" imgSrc={art} url="/portfolio" />
      <DisplayImage text="Mural Wall Tutoral" imgSrc={mural} url="/tutorial" />
      {/* <DisplayImage text="Original Art Bracelets" imgSrc={bracelets} url="/portfolio" />
      <DisplayImage text="Prints, Coasters, and Stickers" imgSrc={prints} url="/portfolio" /> */}
      <DisplayImage text="Gilding Gold Art Classes" imgSrc={classes} url="/classes" />
    </div>
  );
}

export default HomePage;
