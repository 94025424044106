import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import { ScrollRestoration } from "react-router-dom";
import PageRoot from './pages/PageRoot';
import HomePage from './pages/HomePage';
import reportWebVitals from './reportWebVitals';
import FAQPage from './pages/FAQPage';
import ContactPage from './pages/ContactPage';
import PortfolioPage from './pages/PortfolioPage';
import ShopPage from './pages/ShopPage';
import ProductPage from './pages/ProductPage';
import ComingSoonPage from './pages/ComingSoonPage';
import TermsPage from './pages/TermsPage';
import ClassesPage from './pages/ClassesPage';
import TutorialPage from './pages/TutorialPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageRoot />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "/*",
        element: <HomePage />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/faqs",
        element: <FAQPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/about",
        element: <ContactPage />,
      },
      {
        path: "/terms",
        element: <TermsPage />,
      },
      {
        path: "/portfolio",
        element: <PortfolioPage />,
      },
      // {
      //   path: "/shop/:subshop?",
      //   element: <ShopPage />,
      // },
      // {
      //   path: "/product/:itemId",
      //   element: <ProductPage />,
      // },
      {
        path: "/classes",
        element: <ClassesPage />,
      },
      {
        path: "/tutorial",
        element: <TutorialPage />,
      },
      // {
      //   path: "/",
      //   element: <HomePage />,
      // },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
