import './ClassesPage.css';
import mini1 from '../assets/classes/mini1.png'
import mini2 from '../assets/classes/mini2.png'
import mini3 from '../assets/classes/mini3.png'
import mini4 from '../assets/classes/mini4.png'
import charlotte1 from '../assets/classes/charlotte1.png'
import charlotte2 from '../assets/classes/charlotte2.png'
import charlotte3 from '../assets/classes/charlotte3.png'
import charlotte4 from '../assets/classes/charlotte4.png'
import charlotte5 from '../assets/classes/charlotte5.png'
import charlotte6 from '../assets/classes/charlotte6.png'
import coaster1 from '../assets/classes/coaster1.png'
import coaster2 from '../assets/classes/coaster2.png'
import coaster3 from '../assets/classes/coaster3.png'
import coaster4 from '../assets/classes/coaster4.png'
import coaster5 from '../assets/classes/coaster5.png'

const classes = [
  {
    title: "Coaster Classes",
    images: [
      coaster1,
      // coaster2,
      coaster3,
      coaster4,
      coaster5
    ]
  },
  {
    title: "Abstract Mini Art on Canvas Classes",
    subtitle: "with Skillpop",
    images: [
      mini1,
      mini2,
      mini3,
      mini4,
    ]
  },
  {
    title: "Charlotte City Skyline with Gold Art Class",
    subtitle: "with Skillpop",
    images: [
      // charlotte1,
      charlotte2,
      charlotte3,
      charlotte4,
      charlotte5,
      // charlotte6
    ]
  }
]

const ClassesPage = () => {

  return (<>
    <div className="classes-root">
      {classes.map((cl, index) => (<div className="classes-block">
        {!!index && <hr className="class-spacer" />}
        <h2 className="class-name">{cl.title}</h2>
        {cl.subtitle && <span>{cl.subtitle}</span>}
        <div className="classes-image-block">
          {cl.images.map((image) => (<div className="classes-cell">
            <img loading="lazy" className="classes-image" src={image} alt="Class Thumbnail" />
          </div>))}
        </div>
      </div>))}
    </div>
  </>);
}

export default ClassesPage;
