import { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';

const Menu = ({ name, submenues = {} }) => {
  const [open, setOpen] = useState(false)

  const { _, ...rest } = submenues
  const subs = Object.entries(rest)

  const mouseOver = useCallback(() => {
    setOpen(true)
  }, [])

  const mouseOut = useCallback(() => {
    setOpen(false)
  }, [])

  return (<div className="menu-item" onMouseOver={mouseOver} onMouseOut={mouseOut}>
    <div className="menu-link" style={{ color: (_ || !!subs.length) ? '#000000' : '#acacac' }}>
      {_ ? <Link to={_}>{name}</Link> : name}
    </div>
    {(open && !!subs.length) && <div className="sub-menu">
      {subs.map(([menu, link]) => (<div className="sub-menu-item">
        <Link to={link} onClick={mouseOut}>{menu}</Link>
      </div>))}
    </div>}
  </div>);
}

const NavMenu = ({ menues }) => {

  const top = Object.keys(menues) || []

  return (
    <div className="nav-menu">
      {top.map(menu => <Menu name={menu} submenues={menues[menu]} />)}
    </div>
  );
}

export default NavMenu;
