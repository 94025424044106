import './PortfolioPage.css';
import portfolio1 from '../assets/portfolio/portfolio1.png'
import portfolio2 from '../assets/portfolio/portfolio2.png'
import portfolio3 from '../assets/portfolio/portfolio3.png'
import portfolio4 from '../assets/portfolio/portfolio4.png'
import portfolio5 from '../assets/portfolio/portfolio5.png'
import portfolio6 from '../assets/portfolio/portfolio6.png'
import portfolio7 from '../assets/portfolio/portfolio7.png'
import portfolio8 from '../assets/portfolio/portfolio8.png'
import portfolio9 from '../assets/portfolio/portfolio9.png'
import portfolio10 from '../assets/portfolio/portfolio10.png'
import portfolio11 from '../assets/portfolio/portfolio11.png'
import portfolio12 from '../assets/portfolio/portfolio12.png'
import portfolio13 from '../assets/portfolio/portfolio13.png'
import portfolio14 from '../assets/portfolio/portfolio14.png'
import portfolio15 from '../assets/portfolio/portfolio15.png'
import portfolio16 from '../assets/portfolio/portfolio16.png'
import portfolio17 from '../assets/portfolio/portfolio17.png'
import portfolio18 from '../assets/portfolio/portfolio18.png'
import portfolio19 from '../assets/portfolio/portfolio19.png'
import portfolio20 from '../assets/portfolio/portfolio20.png'
import portfolio21 from '../assets/portfolio/portfolio21.png'
import portfolio22 from '../assets/portfolio/portfolio22.png'
import portfolio23 from '../assets/portfolio/portfolio23.png'
import portfolio24 from '../assets/portfolio/portfolio24.png'
import portfolio25 from '../assets/portfolio/portfolio25.png'
import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

// const shape = [ // ARRAY = ROW
//   [1, 1, 1], // NUMBER = # OF IMAGES IN COLUMN
//   [2, 1, [2, 1]], // SUBARRAY + NUMBER = # IN ROW IN COLUMN,
//   [[1, 2], 1, 1],
//   [[2, 1], 1],
//   [2, [1, 2], 2, 2],
//   [2, [2, 1], 2],
//   [1, [2, 1]]
// ]

// const images = [
//   {
//     url: portfolio1,
//     row: 1,
//     column: 1,
//     title: 'Client Cocktail Room Abstract wall, copyright Copeley Designs Art',
//     description: 'Client commissioned a bold luxury cocktail room, with a classy calm feel. Contact copeleydesigns@gmail.com for your own personal abstract wall art. '
//   },
//   {
//     url: portfolio2,
//     row: 1,
//     column: 2,
//     title: 'Artwork, sold, October Sky Copyright Copeley Designs Art',
//     description: 'SOLD, studio works in October 2023'
//   },
//   {
//     url: portfolio3,
//     row: 1,
//     column: 3,
//     title: 'The Winnifred Mural, CLT Skyline',
//     description: 'Apartment complex block CLT Skyline mural with gilded gold leafing hornets and panther. Mural Art by Kelly Aiken.'
//   },
//   {
//     url: portfolio4,
//     row: 2,
//     column: 1,
//     pos: 1,
//     title: 'Abstract Wall Art',
//     description: 'DESCRIPTION HERE'
//   },
//   {
//     url: portfolio5,
//     row: 2,
//     column: 1,
//     pos: 2,
//     title: 'Half wall Abstract Mural art Copyright CD Art',
//     description: 'Commissioned basement wall art, half wall with bold luxury feel. Have ideas for your own wall? email copeleydesigns@gmail.com for quotes.'
//   },
//   {
//     url: portfolio15,
//     row: 2,
//     column: 2,
//     title: 'The Cocktailery in Atherton Mill Mall Charlotte',
//     description: 'Working alongside Little King Art, completing this beautiful "whimsical cocktail ingredient" wall in Charlotte, NC. Contact copeleydesigns@gmail.com for your own mural design consult.'
//   },
//   {
//     url: portfolio9,
//     row: 2,
//     column: 3,
//     subrow: 1,
//     pos: 1,
//     title: 'Minted x West Elm winner- "Treasure Island" by Kelly Aiken',
//     description: 'Prints Available on minted.com and westelm.com, original art RETIRED, Abstract by Kelly Aiken of Copeley Designs Art.'
//   },
//   {
//     url: portfolio8,
//     row: 2,
//     column: 3,
//     subrow: 1,
//     pos: 2,
//     title: '"Ever Changing Emotions" by Kelly Aiken 2022',
//     description: "Solo Show at The Artisan's Palate, May 2022 in Charlotte NC. SOLD at solo show."
//   },
//   {
//     url: portfolio7,
//     row: 2,
//     column: 3,
//     subrow: 2,
//     title: 'Bohemian Style Bold Decor Room',
//     description: 'DESCRIPTION HERE'
//   },
//   {
//     url: portfolio10,
//     row: 3,
//     column: 1,
//     subrow: 1,
//     pos: 1,
//     title: 'All the Feels by Kelly Aiken',
//     description: 'SOLD, by Art House Charlotte, see more work by Kelly Aiken at Art House Charlotte in South end area.'
//   },
//   {
//     url: portfolio11,
//     row: 3,
//     column: 1,
//     subrow: 2,
//     title: 'New York City Skyline Commission',
//     description: 'Commission, SOLD'
//   },
//   {
//     url: portfolio12,
//     row: 3,
//     column: 1,
//     subrow: 2,
//     pos: 2,
//     title: 'Tidal Wave by Kelly Aiken',
//     description: 'SOLD. Gold Leaf and Acrylic on paperworks, released in spring collection 2020.'
//   },
//   {
//     url: portfolio13,
//     row: 3,
//     column: 2,
//     title: 'Ombre Beach Painting by Kelly Aiken, SOLD',
//     description: 'SOLD from Abstracted Summer Collection by Kelly Aiken, 2020. Copyright Copeley Designs Art'
//   },
//   {
//     url: portfolio14,
//     row: 3,
//     column: 3,
//     title: 'Kitchen Design by Kelly Aiken',
//     description: 'COPYRIGHT COPELEY DESIGNS ART 2023, hand painted wall murals, gilded gold walls in kitchen space'
//   },
//   {
//     url: portfolio6,
//     row: 4,
//     column: 1,
//     subrow: 1,
//     pos: 1,
//     title: 'Solo Show, "Changing the Scenery", 2022 Available',
//     description: `Solo Show at The Artisan's Palate. May 2022. Large Art "Changing the Scenery" acrylic, pigment, and resin with gold leaf.`
//   },
//   {
//     url: portfolio16,
//     row: 4,
//     column: 1,
//     subrow: 1,
//     pos: 2,
//     title: 'Stolen Heart by Kelly Aiken of Copeley Designs Art',
//     description: 'SOLD through Art House Charlotte Gallery in Charlotte NC. Art by Kelly Aiken, copeleydesigns.com'
//   },
//   {
//     url: portfolio21,
//     row: 4,
//     column: 1,
//     subrow: 2,
//     title: 'Rented and Staged work in Huntersville, NC by Kelly Aiken',
//     description: 'Rent or stage with Copeley Designs Art! These "Golden Tundra" diptych works are available for purchase, contact copeleydesigns@gmail.com'
//   },
//   {
//     url: portfolio18,
//     row: 4,
//     column: 2,
//     title: 'Vibing the Morning Weather by Kelly Aiken',
//     description: 'SOLD.'
//   },
//   {
//     url: portfolio19,
//     row: 5,
//     column: 1,
//     title: 'Golden Haze by Kelly Aiken',
//     description: 'Commissioned piece, SOLD'
//   },
//   {
//     url: portfolio20,
//     row: 5,
//     column: 1,
//     title: 'Client Cocktail Room Abstract wall, copyright Copeley Designs Art',
//     description: 'Client commissioned a bold luxury cocktail room, with a classy calm feel. Contact copeleydesigns@gmail.com for your own personal abstract wall art. '
//   },
//   {
//     url: portfolio17,
//     row: 5,
//     column: 2,
//     title: 'Artwork, sold, October Sky Copyright Copeley Designs Art',
//     description: 'SOLD, studio works in October 2023'
//   },
//   {
//     url: portfolio22,
//     row: 5,
//     column: 3,
//     title: 'The Winnifred Mural, CLT Skyline',
//     description: 'Apartment complex block CLT Skyline mural with gilded gold leafing hornets and panther. Mural Art by Kelly Aiken.'
//   },
//   // {
//   //   url: portfolio20,
//   //   row: 5,
//   //   column: 1,
//   //   pos: 2,
//   //   title: 'Gold Leaf Block Wall, Robinson Ortho',
//   //   description: 'Wall designed by Kim  McMillian, Charlotte Interior designer. Painting and Gold Leaf work completed by Kelly Aiken. Artist executed designers look with a gold leaf and neutral mural in an orthodontist space.'
//   // },
//   // {
//   //   url: portfolio21,
//   //   row: 5,
//   //   column: 2,
//   //   subrow: 1,
//   //   pos: 1,
//   //   title: 'Whirlwind by Kelly Aiken',
//   //   description: 'Available, email copeleydesigns@gmail.com'
//   // },
//   // {
//   //   url: portfolio22,
//   //   row: 5,
//   //   column: 2,
//   //   subrow: 2,
//   //   title: 'Charlotte Skyline wall mural, copyright COPELEY DESIGNS ART',
//   //   description: 'COPELEY DESIGNS ART, copyright wall art, hand painted hand blended wall art'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 5,
//   //   column: 2,
//   //   subrow: 2,
//   //   pos: 2,
//   //   title: 'Mural Walls, Drip design, artist Kelly Aiken',
//   //   description: 'Abstract Art murals by Kelly Aiken, tutorial on Copeley Designs Shop page, learn all the tips and tricks to making your own Abstract wall with gold leaf. Original statement accent walls for any interior space.'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 5,
//   //   column: 3,
//   //   title: 'Gold Leaf Wall',
//   //   description: 'As seen in Wall Mural Tutorial DESCRIPTION HERE'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 5,
//   //   column: 3,
//   //   pos: 2,
//   //   title: 'Charlotte Skyline Commission',
//   //   description: 'Charlotte, NC Commission, SOLD'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 5,
//   //   column: 4,
//   //   title: 'Mural Accent Wall Selfie by Kelly Aiken',
//   //   description: 'Book your wall art for 2021 now! Artist Kelly Aiken comes into your home for beautiful accent walls with gold metal leafing, murals to bring your space glam.'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 5,
//   //   column: 4,
//   //   pos: 2,
//   //   title: 'Light within the Shadows',
//   //   description: "Chosen Women's Apparel and Fine Art"
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 6,
//   //   column: 1,
//   //   title: 'Commissioned Large Abstract by Kelly Aiken',
//   //   description: 'SOLD.'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 6,
//   //   column: 1,
//   //   pos: 2,
//   //   title: 'Dining room Client Wall Mural by Kelly Aiken',
//   //   description: 'Copyright Copeley Designs Art, wall mural statement designs, boho style, jungalow style by Kelly Aiken'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 6,
//   //   column: 2,
//   //   subrow: 1,
//   //   pos: 1,
//   //   title: 'Charlotte NC Skyline by Artist Kelly Aiken. Commissioned',
//   //   description: 'SOLD. Skyline Artist Kelly Aiken, Panthers colors, with silver leafing skyline art, custom skyline city art for any space, man cave includedcopeleydesigns.com'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 6,
//   //   column: 2,
//   //   subrow: 1,
//   //   pos: 2,
//   //   title: 'That September Day by Kelly Aiken of Copeley Designs Art',
//   //   description: 'Original SOLD, from The "Acceptance Series" 2019. Need a print? See Shop online for available gold leaf prints, hand gilded by Kelly Aiken.'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 6,
//   //   column: 2,
//   //   subrow: 2,
//   //   pos: 1,
//   //   title: 'Gold Leaf Wall Art Mural by Artist Kelly, Commissioned 2021',
//   //   description: 'Gold Leaf mural walls, by Kelly Aiken. Add shine and texture to your living space with original art walls. copeleydesigns.com'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 6,
//   //   column: 3,
//   //   title: '"Distorted in Gold", canvas art with resin and gold reflective chrome pigment.',
//   //   description: `This piece was featured at "Distorting Reflections" Show at the Artisan's Palette in CLT, NC. Exploration of dermatillomania and mental health awareness.`
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 6,
//   //   column: 3,
//   //   pos: 2,
//   //   title: 'Gymnastics Center Abstract TUCK 37 mural art',
//   //   description: 'Abstract and Gold Leaf wall art by Kelly Aiken of Copeley Designs Art. Art for commercial business spaces, located in SC.'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 7,
//   //   column: 1,
//   //   title: 'Winnifred Outdoor Mural',
//   //   description: 'DESCRIPTION HERE'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 7,
//   //   column: 2,
//   //   subrow: 1,
//   //   pos: 1,
//   //   title: 'Wall Mural ADD TITLE',
//   //   description: 'Wall Mural, teaming with Play Wild Child- interiors for playrooms! Bold linework in boy and girl playroom space.'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 7,
//   //   column: 2,
//   //   subrow: 1,
//   //   pos: 2,
//   //   title: 'Commissioned Dog Artwork by Kelly Aiken',
//   //   description: 'Whimsical dog commissions, RIP sweet dogs! Watercolor, Acrylic, Gilded Gold, and micron pen details.'
//   // },
//   // {
//   //   url: portfolio,
//   //   row: 7,
//   //   column: 2,
//   //   subrow: 2,
//   //   pos: 1,
//   //   title: 'Orthodontist block wall art by Kelly Aiken, copyright 2023',
//   //   description: 'Copyright 2023 Copeley Designs, Colorful wall art block modern style, Fort Mill location'
//   // },
// ]

// 3-4-3-2-3-4-3

const images = [
  {
    url: portfolio1,
    row: 1,
    column: 1,
    title: 'The Winnifred, a bold apartment complex mural telling the history of Charlotte NC',
    description: ' A Whimsical and Retro Vibe to capture the Charlotte Skyline, Bank of America Stadium, train tracks, and gilded hornets (in honor of the Charlotte Hornets). Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio4,
    row: 1,
    column: 2,
    title: 'Mural in Bathroom, Residential, 2024',
    description: 'Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio3,
    row: 1,
    column: 3,
    title: 'Black and White hand painted bathroom mural, abstract art by Kelly Aiken',
    description: 'Copyright Copeley Designs Art'
  },
  {
    url: portfolio6,
    row: 2,
    column: 1,
    title: 'Canvas and artist in studio',
    description: 'Contact Kelly Aiken at copeleydesigns@gmail.com to inquire about available gold leaf works. Studio Moment - Gold Leaf Art by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio19,
    row: 2,
    column: 2,
    pos: 1,
    title: 'Client Abstract Mural, Residential Interior Living Room Space, 2020',
    description: 'Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio21,
    row: 2,
    column: 2,
    pos: 2,
    title: 'Client Abstract Mural, 2020, Residential Interior Living Room Space',
    description: 'Large Abstract Art Wall Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio18,
    row: 2,
    column: 3,
    title: 'West Elm Summer 2019, sold in stores all over the United States',
    description: 'Gold Leaf Art Canvas and Prints by Kelly Aiken x Minted x West Elm, Copyright Copeley Designs Art'
  },
  {
    url: portfolio2,
    row: 3,
    column: 1,
    title: 'October Sky, Sold 2023',
    description: 'Gold Leaf Art Canvas and framed by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio5,
    row: 3,
    column: 2,
    title: 'Residential Client, Interior Living Room Space 2020',
    description: 'Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio7,
    row: 3,
    column: 3,
    title: 'Fern and Ivy Hair Haus in Charlotte, NC',
    description: 'Interior Mural for hair salon space. Gold Leaf Abstract Drip Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio20,
    row: 4,
    column: 1,
    title: 'Gold Leaf Art prints available',
    description: 'Original New York Skyline Watercolor art by Kelly Aiken, prints hand gilded by artist in studio.  Copyright Copeley Designs Art'
  },
  {
    url: portfolio22,
    row: 4,
    column: 2,
    title: 'Artist Kelly Aiken\'s Mental Health Art Show, 2022',
    description: 'Large Canvas works in the collection "Distorted Reflections". Large Framed Art available. Copyright Copeley Designs Art'
  },
  {
    url: portfolio13,
    row: 5,
    column: 1,
    title: 'Organic Abstract Greenery Mural, Interior Residential Bathroom Space, 2021',
    description: 'Gold Detailed Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio14,
    row: 5,
    column: 2,
    title: 'Mural for Orthodontist Office, Interior Wall Painting in Fort Mill SC, 2023',
    description: 'Large Abstract Color Block design by Kelly Aiken.  Copyright Copeley Designs Art'
  },
  {
    url: portfolio15,
    row: 5,
    column: 3,
    title: 'Artist Kelly Aiken featured in Charlotte Magazine January 2022',
    description: 'Art for the home, Interior drip wall mural art, gilded gold art for residential and commercial spaces. Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio16,
    row: 6,
    column: 1,
    title: 'Client Dentist Office in Waxhaw, NC, 2021',
    description: ' Art for Commercial Interior Spaces. Kintsugi abstract wall art. Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio8,
    row: 6,
    column: 2,
    pos: 1,
    title: 'Custom Canvas City Skyline Artwork with custom frame',
    description: 'New York City Skyline view. Gold Leaf Art in studio by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio9,
    row: 6,
    column: 2,
    pos: 2,
    title: 'Abstract Residential Wall for Children\'s Playspace/Room',
    description: 'Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio17,
    row: 6,
    column: 3,
    title: 'Artist Kelly Aiken, abstract gilding in action, client\'s personal Interior gym and playroom space, 2024',
    description: 'Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio10,
    row: 7,
    column: 1,
    title: 'Mini Art for the home, residential canvas and paper works for bold and glamorous additions to any space',
    description: 'Contact copeleydesigns@gmail.com for available works. Copyright Copeley Designs Art'
  },
  {
    url: portfolio11,
    row: 7,
    column: 2,
    title: 'Kitchen space mural, residential client interior space, 2023',
    description: 'Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio12,
    row: 7,
    column: 3,
    title: 'Large and Mini Holographic and Gold Abstract Art for the home, residential canvas pieces for bold and glamorous additions to any space',
    description: 'SOLD, studio works in October 2023'
  },
  {
    url: portfolio24,
    row: 8,
    column: 1,
    title: 'Mural in Bathroom, Residential, 2024',
    description: 'Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  {
    url: portfolio25,
    row: 8,
    column: 1,
    title: 'The Winnifred exterior curbside, this color combination connects to the interior bold apartment complex mural in Charlotte NC',
    description: ' Hidden tiny 24k hornets also located on this curbside piece. Gold Leaf Art Mural by Kelly Aiken, Copyright Copeley Designs Art'
  },
  // {
  //   url: portfolio6,
  //   row: 8,
  //   column: 1,
  //   title: 'Client Cocktail Room Abstract wall, copyright Copeley Designs Art',
  //   description: 'Client commissioned a bold luxury cocktail room, with a classy calm feel. Contact copeleydesigns@gmail.com for your own personal abstract wall art. '
  // },
  // {
  //   url: portfolio19,
  //   row: 8,
  //   column: 2,
  //   pos: 1,
  //   title: 'Artwork, sold, October Sky Copyright Copeley Designs Art',
  //   description: 'SOLD, studio works in October 2023'
  // },
  // {
  //   url: portfolio21,
  //   row: 8,
  //   column: 2,
  //   pos: 2,
  //   title: 'The Winnifred Mural, CLT Skyline',
  //   description: 'Apartment complex block CLT Skyline mural with gilded gold leafing hornets and panther. Mural Art by Kelly Aiken.'
  // },
  // {
  //   url: portfolio18,
  //   row: 8,
  //   column: 3,
  //   title: 'The Winnifred Mural, CLT Skyline',
  //   description: 'Apartment complex block CLT Skyline mural with gilded gold leafing hornets and panther. Mural Art by Kelly Aiken.'
  // },
]

const shape = images.reduce((p, c, index) => {
  p[(c.row || 1) - 1] = p[(c.row || 1) - 1] || []
  if (c.subrow) {
    p[(c.row || 1) - 1][(c.column || 1) - 1] = p[(c.row || 1) - 1][(c.column || 1) - 1] || []
    p[(c.row || 1) - 1][(c.column || 1) - 1][c.subrow - 1] = p[(c.row || 1) - 1][(c.column || 1) - 1][c.subrow - 1] || { images: [] }
    p[(c.row || 1) - 1][(c.column || 1) - 1][c.subrow - 1].images[(c.pos || 1) - 1] = { url: c.url, index }
  } else {
    p[(c.row || 1) - 1][(c.column || 1) - 1] = p[(c.row || 1) - 1][(c.column || 1) - 1] || { images: [] }
    p[(c.row || 1) - 1][(c.column || 1) - 1].images[(c.pos || 1) - 1] = { url: c.url, index }
  }
  return p
}, [])

const PortfolioImage = ({ img, index, onClick }) => {

  const handleClick = useCallback(() => {
    console.log("HESSS", index)
    onClick(index)
  }, [index, onClick])

  return (
    <div className="portfolio-cell" onClick={handleClick}>
      <img loading="lazy" className="portfolio-image" src={img} alt="Portfolio Thumbnail" />
    </div>
  );

}

const PortfolioPage = () => {
  const [index, setIndex] = useState(null)
  const [hide, setHide] = useState(true)

  const handleClose = useCallback(() => {
    setHide(true)
    setTimeout(() => setIndex(null), 500)
  }, [])

  const handleSelect = useCallback((idx) => {
    setIndex(idx)
    setHide(false)
  }, [])

  const handleBack = useCallback(() => {
    setIndex(prev => Math.max(prev - 1, 0))
  }, [])

  const handleForward = useCallback(() => {
    setIndex(prev => Math.min(prev + 1, images.length - 1))
  }, [images])

  const current = useMemo(() => {
    return index !== null ? images[index] : null
  }, [index, images])

  return (<>
    <div className="portfolio-root">
      <h2>Copeley Designs Portfolio</h2>
      <span>Commissioned Murals, Retired Works, and Sold Works<br />2015-2024</span>
      <div className="portfolio-block">
        {/* {shape.map(row => (<div className="portfolio-row">
          {row.map(column => {
            return Array.isArray(column) ? <div className="portfolio-column">
              {column.map(subrow =>
                <div className="portfolio-subrow">
                  {subrow.images.map((image) => <PortfolioImage img={image.url} index={image.index} onClick={handleSelect} />)}
                </div>
              )}
            </div> : <div className="portfolio-column">
              {column.images.map((image) => <PortfolioImage img={image.url} index={image.index} onClick={handleSelect} />)}
            </div>
          })}
        </div>))} */}
        {images.map((image, index) => (
          <PortfolioImage key={index} img={image.url} index={index} onClick={handleSelect} />
        ))}
      </div>
    </div>
    {current && createPortal(<div className="portfolio-full" style={{ opacity: hide ? 0 : 1, animationFillMode: hide ? 'none' : 'forwards' }}>
      <button className="portfolio-full-close" onClick={handleClose}>&#215;</button>
      <div className="portfolio-full-carosuel">
        <div className="portfolio-full-right">
          <button className="portfolio-full-button" onClick={handleBack} disabled={!index}>&lsaquo;</button>
        </div>
        <div className="portfolio-full-center">
          <img src={current.url} alt="Full Photo" className="portfolio-full-photo" />
        </div>
        <div className="portfolio-full-right">
          <button className="portfolio-full-button" onClick={handleForward} disabled={index === images.length - 1}>&rsaquo;</button>
        </div>
      </div>
      <div className="portfolio-full-text">
        <div className="portfolio-full-text-block">
          <p className="portfolio-full-text-title">{current.title}</p>
          <p className="portfolio-full-text-desc">{current.description}</p>
        </div>
      </div>
    </div >, document.body)
    }
  </>);
}

export default PortfolioPage;
